/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import Button from "components/button";
import { useMemo, useState } from "react";
import { Continue, DepositStockWrapper } from "../../styles";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector } from "redux/reducers/hook";
import { getStocks } from "redux/reducers/cryptos/cryptos.selector";

const View = () => {
  const history = useHistory();
  const [method, setMethod] = useState("");
  const { stock } = useParams();

  const stocks = useAppSelector(getStocks);

  const handleClick = () => {
    history.push(`/my/wallets/${stock}/withdraw/${method}`);
  };

  const currencies = [
    {
      name: "p2p",
      icon: "/assets/images/coins/p2p.png",
      disable: false,
      open: "is_p2p",
    },
    {
      name: "spot",
      icon: "/assets/images/coins/spot.png",
      disable: false,
      open: "is_spot",
    },
    {
      name: "qr-mart",
      icon: "/assets/images/coins/spot.png",
      disable: false,
      open: "is_qrmart",
    },
    {
      name: "cv",
      icon: "/assets/images/coins/spot.png",
      disable: false,
      open: "is_cv",
    },
    {
      name: "vdiarybook",
      icon: "/assets/images/coins/spot.png",
      disable: false,
      open: "is_vdiarybook",
    },
  ];

  const _currencies = useMemo(() => {
    if (stocks) {
      const _stock = stocks.find((s) => s.symbol === stock);
      if (_stock) return currencies.filter((c) => _stock[c.open]);
      return [];
    }
    return [];
  }, [stocks, stock]);

  return (
    <DepositStockWrapper>
      <h5>
        {String(stock || "").toUpperCase()} - Please select the method you want
        to withdraw{" "}
      </h5>
      {_currencies.map((c) => (
        <Button
          className={`btn-currency  ${method === c.name ? "active" : ""}`}
          onClick={() => setMethod(c.name)}
          disabled={c.disable}
        >
          <img src={c.icon} />
          {c.name.toUpperCase()}
        </Button>
      ))}
      <Continue>
        <Button onClick={handleClick} disabled={!method}>
          Continue
        </Button>
      </Continue>
    </DepositStockWrapper>
  );
};
export default View;
