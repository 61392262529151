/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import Table from "components/table";
import { SpanColor, Tab, Tabs, Title, HistoryTransfer } from "../../styles";

import APIs from "api";
import { useEffect, useState } from "react";
import { renderAmount } from "utils/render";

const View = (props) => {
  const [tab, setTab] = useState<string>("deposit");
  const [dataHistory, setDataHistory] = useState<any>([]);
  const [dataLocks, setDataLocks] = useState([]);

  const columns = [
    tab === "deposit" && {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    tab === "withdraw" && {
      title: "To",
      dataIndex: "to",
      key: "to",
      render: (data: any) => <span>{data.to.slice(0, 20)}...</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (data: any) => (
        <SpanColor color="orange100">{renderAmount(data.amount)}</SpanColor>
      ),
    },
    {
      title: "Amount Locked",
      dataIndex: "amountLocked",
      key: "amountLocked",
      render: (data: any) => (
        <SpanColor color="orange100">
          {renderAmount(data.amountLocked)}
        </SpanColor>
      ),
    },
    {
      title: "Amount Received",
      dataIndex: "amountReceived",
      key: "amountReceived",
      render: (data: any) => (
        <SpanColor color="orange100">
          {renderAmount(data.amountReceived || data.amount)}
        </SpanColor>
      ),
    },
    tab === "changes" && {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    tab !== "changes" && {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
      render: (data: any) => (
        <>
          {" "}
          <SpanColor color="orange100">{data.fee.amount}</SpanColor> (
          {data.fee.symbol})
        </>
      ),
    },
    tab === "withdraw" && {
      title: "VAT",
      dataIndex: "VAT",
      key: "VAT",
      render: (data: any) => (
        <>
          <SpanColor color="orange100">{data?.VAT || 0}</SpanColor>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data: any) => (
        <SpanColor
          color={
            data.status === "success"
              ? "green100"
              : data.status === "pending"
              ? "yellow100"
              : "red100"
          }
        >
          {data.status}
        </SpanColor>
      ),
    },
    {
      title: "Note",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: any) => (
        <span>{new Date(data.createdAt).toLocaleString()}</span>
      ),
    },
    {
      key: "hash",
      title: "Hash",
      render: (item: any) => (
        <a
          href={`${
            process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
          }/tx/${item.hash}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {item.hash?.slice(0, 10)}...
        </a>
      ),
    },
    {
      key: "invoice",
      title: "Invoice",
      render: (item: any) => (
        <a
          href={`/invoice/transactions/${tab}/${item._id}`}
          target={"_blank"}
          rel="noreferrer"
        >
          View
        </a>
      ),
    },
  ];

  const columnsLock = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (data: any) => (
        <SpanColor color="orange100">{renderAmount(data.amount)}</SpanColor>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: any) => (
        <span>{new Date(data.createdAt).toLocaleString()}</span>
      ),
    },
  ];

  const getHistory = async () => {
    try {
      if (tab) {
        if (tab === "locks") {
          const res: any = await APIs.TRANSACTION.getLocks({
            params: {
              stock: props.match.params.stock,
            },
          });
          if (res && res.content) {
            setDataLocks(res.content);
          } else setDataLocks([]);
        } else {
          const res: any = await APIs.TRANSACTION.getTransactions({
            query: {
              stock: props.match.params.stock,
              type: tab,
            },
          });
          setDataHistory(res?.transactions);
        }
      } else {
        setDataHistory([]);
        setDataLocks([]);
      }
    } catch (error) {
      setDataHistory([]);
      setDataLocks([]);
    }
  };

  useEffect(() => {
    getHistory();
  }, [tab, props.match.params.stock]);

  return (
    <>
      <Title bgColor={"grey200"} just="space-between">
        <p>History</p>
      </Title>
      <Tabs>
        <Tab
          isActive={tab === "deposit" && true}
          onClick={() => setTab("deposit")}
        >
          Deposit
        </Tab>
        <Tab
          isActive={tab === "withdraw" && true}
          onClick={() => setTab("withdraw")}
        >
          Withdraw
        </Tab>
        <Tab
          isActive={tab === "changes" && true}
          onClick={() => setTab("changes")}
        >
          Changes
        </Tab>
        <Tab isActive={tab === "locks" && true} onClick={() => setTab("locks")}>
          Lock And Unlock
        </Tab>
      </Tabs>
      <HistoryTransfer>
        <div>
          {tab === "locks" ? (
            <Table
              columns={columnsLock.filter((a) => a)}
              dataSource={dataLocks}
              bgTable={"grey200"}
            ></Table>
          ) : (
            <Table
              columns={columns.filter((a) => a)}
              dataSource={dataHistory}
              bgTable={"grey200"}
            ></Table>
          )}
        </div>
      </HistoryTransfer>
    </>
  );
};

export default View;
